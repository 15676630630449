import { useSearch } from '../../../hooks/context';
import { getVisibleFilters } from './helpers';

const useAppliedFilters = ({ isInternal = false } = {}) => {
  const {
    filtering_options: { applied_filters: appliedFilters = [] } = {},
    map_configuration: { show_internal_qcats: showInternal = false } = {},
  } = useSearch();

  const visibleFilters = getVisibleFilters(appliedFilters);
  const showFilters = isInternal === showInternal;

  return visibleFilters && visibleFilters.length > 0 && showFilters ? visibleFilters : null;
};

export default useAppliedFilters;
