import React from 'react';

import { loadable } from 'nordic/lazy';

const ChunkToolbar = () => {
  const Toolbar = loadable(async () => import(/* webpackChunkName: "chunk-toolbar" */ './toolbar.main.mobile'));

  return <Toolbar />;
};

export default ChunkToolbar;
