import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-condition';

const GetConditionText = ({ condition, conditionText }) => {
  switch (condition) {
    case 'refurbished':
      return conditionText;
    case 'used':
      return conditionText;

    default:
      return '';
  }
};

const Condition = ({ className, item }) => (
  <p className={classnames(namespace, className)}>
    <GetConditionText condition={item.condition} conditionText={item.condition_text} />
  </p>
);

Condition.propTypes = {
  className: string,
  item: shape().isRequired,
};

Condition.defaultProps = {
  className: null,
};

export default Condition;
