import React, { useRef } from 'react';
import { shape, string, bool } from 'prop-types';

import Image from 'nordic/image';
import classnames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import Card, { CardContent } from '@andes/card';

import Carousel from '../carousel-snapped/carousel.mobile';
import ItemBookmark from '../../../../components-v2/bookmark/bookmark';
import CardContentMobile from './card-content-mobile.pi';
import Link from '../../../../components-v2/link';
import { VERTICAL_TYPE_RES, namespace } from '../constants';
import { LAYOUTS } from '../../../../constants';

function renderRealEstateAgencyLogo(item, options) {
  const superPremium = item.tags && includes(item.tags, 'super_premium');

  if (superPremium) {
    const realEstateAgencyLogo = get(item, 'seller_info.real_estate_agency_logo');
    const realEstateFantasyName = get(item, 'seller_info.real_estate_agency_fantasy_name');

    if (realEstateAgencyLogo) {
      return (
        <Image
          className="real-estate-agency-logo__image"
          src={realEstateAgencyLogo}
          lazyload="on"
          lowEnd={options.lowEnd === true}
          alt={realEstateFantasyName}
        />
      );
    }
  }

  return null;
}

const LAZY_ON = 'on';

const MobileLayoutItemPI = ({ item, layout, index, onMount, options }) => {
  const {
    title,
    permalink,
    vertical,
    subtitles,
    possession,
    available_units,
    highlighted,
    target,
    image_ratio,
    pictures,
  } = item;
  const itemVertical = vertical ? vertical.toLowerCase() : null;

  const wrapperClassname = classnames(
    `${namespace}__item_wrapper`,
    { [`${namespace}--${itemVertical}__item_wrapper`]: itemVertical },
    `${namespace}__item_wrapper--pi`,
  );
  const imageContainerClassname = classnames(`${namespace}__item-image-container`, {
    [`${namespace}--${itemVertical}__item-image-container`]: itemVertical,
  });
  const imageLinkClassname = classnames(`${namespace}__image__link`, {
    [`${namespace}__image__link--${itemVertical}`]: itemVertical,
  });
  const figureClassname = classnames(`${namespace}__image_container`, {
    [`${namespace}__image_container--${itemVertical}`]: itemVertical,
  });

  const linkClassname = classnames(`${namespace}__link`, { [`${namespace}__link--pill-p`]: item.is_ad });

  const cardRef = useRef(null);

  return (
    <Card animated={false} ref={cardRef}>
      <CardContent className={classnames(namespace, { [`${namespace}--${itemVertical}`]: itemVertical })}>
        <div className={wrapperClassname}>
          <div className={imageContainerClassname}>
            {vertical === VERTICAL_TYPE_RES && layout === LAYOUTS.TYPE_GALLERY ? (
              <Carousel
                item={item}
                index={index}
                layout={layout}
                onMount={onMount}
                cardRef={cardRef}
                vertical={vertical}
                imageRatio={image_ratio}
                itemVertical={itemVertical}
                title={title}
                permalink={permalink}
                options={options}
                pictures={pictures}
              />
            ) : (
              <Link
                className={imageLinkClassname}
                href={permalink}
                title={title}
                tabIndex="-1"
                isInternal={false}
                target={target}
              >
                <figure className={figureClassname}>
                  {renderRealEstateAgencyLogo(item, options)}
                  <Image
                    alt={title}
                    className={`${namespace}__image`}
                    src={get(pictures, `${layout}.retina`)}
                    width={get(pictures, `${layout}.tags.width`)}
                    height={get(pictures, `${layout}.tags.heigth`)}
                    lazyload={LAZY_ON}
                    lowEnd={options.lowEnd === true}
                  />
                </figure>
              </Link>
            )}
          </div>
          <CardContentMobile
            item={item}
            layout={layout}
            vertical={vertical}
            subtitles={subtitles}
            possession={possession}
            available_units={available_units}
            options={options}
            highlighted={highlighted}
            className={linkClassname}
            permalink={permalink}
            title={title}
            target={target}
          />
        </div>

        <ItemBookmark item={item} />
      </CardContent>
    </Card>
  );
};

MobileLayoutItemPI.propTypes = {
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  options: shape({
    lowEnd: bool,
  }),
};

MobileLayoutItemPI.defaultProps = {
  options: {},
};

export default MobileLayoutItemPI;
