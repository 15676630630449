import React, { useState } from 'react';

import get from 'lodash/get';
import classnames from 'classnames';
import Image from 'nordic/image';
import includes from 'lodash/includes';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';

import { namespace, CAROUSEL_SETTINGS } from '../constants';
import { normalizePictureData, IsNotCropImage } from '../utils';
import Link from '../../../../components-v2/link';
import useItem from '../../../../hooks/use-item';
import { useStaticProps } from '../../../context/static-props';
import { VISUAL_IDS } from '../../../../constants';

import useTrackFirstSwipe from '../../../../hooks/use-track-first-swipe';

function renderRealEstateAgencyLogo(item, options) {
  const superPremium = item.tags && includes(item.tags, 'super_premium');

  if (superPremium) {
    const realEstateAgencyLogo = get(item, 'seller_info.real_estate_agency_logo');
    const realEstateFantasyName = get(item, 'seller_info.real_estate_agency_fantasy_name', '');

    if (realEstateAgencyLogo) {
      return (
        <Image
          className="real-estate-agency-logo__image"
          src={realEstateAgencyLogo}
          lazyload="on"
          lowEnd={options.lowEnd === true}
          alt={realEstateFantasyName}
        />
      );
    }
  }

  return null;
}

const Carousel = ({
  pictures,
  item,
  index,
  layout,
  onMount,
  cardRef,
  vertical,
  imageRatio,
  itemVertical,
  title,
  permalink,
  isAd,
  options,
}) => {
  const itemContext = useItem({ index, middlewareItem: item, layout, onMount, cardRef });

  const { selectedVariation, itemPictures, handleCardHover: handleRequestCarousel, itemPicturesTracks } = itemContext;

  const [carouselSettings] = useState(CAROUSEL_SETTINGS);

  const shouldNotCropImage = IsNotCropImage(vertical, imageRatio);

  const imageLinkClassname = classnames(`${namespace}__image__link`, {
    [`${namespace}__image__link--${itemVertical}`]: itemVertical,
  });

  const { visualId } = useStaticProps();

  const isPI = visualId === VISUAL_IDS.PI;

  const figureGalleryClassname = classnames(`${namespace}__image_container${isPI ? '' : '--gallery'}`, {
    [`${namespace}__image_container--${itemVertical}--gallery`]: itemVertical,
  });

  const { swipe_gallery_tracks } = itemPicturesTracks ?? {};
  const { handleGallerySwipe } = useTrackFirstSwipe(swipe_gallery_tracks);

  if (itemPictures.length > 1) {
    return (
      <>
        <CarouselSnapped
          key={get(selectedVariation, 'id', get(item, 'id'))}
          strictBoundaries={false}
          pagination={false}
          type="full"
          srLabel={title}
          slidesPerView={carouselSettings.slidesToShow}
          arrows
          arrowsVisible
          infinite={carouselSettings.infinite}
          lazyload
          initialSlide={1}
          className={`${namespace}__card`}
          afterChange={handleGallerySwipe}
        >
          {itemPictures
            .map((picture) => ({
              picture: normalizePictureData(picture, title, options, shouldNotCropImage),
              itemPicture: item,
            }))
            .map(({ picture, itemPicture }) => (
              <CarouselSnappedSlide key={picture.src}>
                <Link
                  title={itemPicture.title}
                  href={itemPicture.permalink}
                  isInternal={false}
                  isAd={itemPicture.is_ad}
                  className={imageLinkClassname}
                >
                  <figure className={classnames(figureGalleryClassname)}>
                    <Image
                      alt={picture.title}
                      className={isPI ? '' : `${namespace}__image`}
                      src={picture.src}
                      preload
                      lazyload={picture.lazyload}
                      lowEnd
                      width={picture.width}
                      height={picture.height}
                    />
                  </figure>
                </Link>
              </CarouselSnappedSlide>
            ))}
        </CarouselSnapped>
        <div className={`${namespace}__real-estate-logo-container`}>{renderRealEstateAgencyLogo(item, options)}</div>
      </>
    );
  }

  return (
    <>
      <CarouselSnapped
        strictBoundaries={false}
        pagination={false}
        type="full"
        srLabel={title}
        slidesPerView={carouselSettings.slidesToShow}
        arrows
        arrowsVisible
        infinite={carouselSettings.infinite}
        lazyload
        className={`${namespace}__card`}
        onClick={itemPictures.length <= 1 && handleRequestCarousel}
      >
        <CarouselSnappedSlide>
          <Link className={imageLinkClassname} href={permalink} title={title} isInternal={false} isAd={isAd}>
            <figure
              className={figureGalleryClassname}
              style={{ height: 284 }}
              onTouchStart={itemPictures.length <= 1 && handleRequestCarousel}
            >
              <Image
                alt={title}
                className={isPI ? '' : `${namespace}__image`}
                src={get(pictures, `${layout}.retina`)}
                width={get(pictures, `${layout}.tags.width`)}
                height={get(pictures, `${layout}.tags.heigth`)}
                preload
                lazyload
                lowEnd
              />
            </figure>
          </Link>
        </CarouselSnappedSlide>
        <CarouselSnappedSlide>
          <Link className={imageLinkClassname} href={permalink} title={title} isInternal={false} isAd={isAd}>
            <figure
              className={figureGalleryClassname}
              style={isPI ? null : { height: 284 }}
              onTouchStart={itemPictures.length <= 1 && handleRequestCarousel}
            >
              <div className={`${namespace}__progress-bar-container`}>
                <ProgressIndicatorCircular
                  modifier="block"
                  className="spinner-image"
                  id="my-progress-indicator"
                  srLabel="Carga en proceso"
                />
              </div>
            </figure>
          </Link>
        </CarouselSnappedSlide>
      </CarouselSnapped>
      <div className={`${namespace}__real-estate-logo-container`}>{renderRealEstateAgencyLogo(item, options)}</div>
    </>
  );
};

export default Carousel;
