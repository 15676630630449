import React from 'react';

import { loadable } from 'nordic/lazy';

import useAdvertisingAdn from './use-advertising-adn';

const ChunkAdvertisingAdn = () => {
  const advertisignAdnProps = useAdvertisingAdn();

  const AdvertisingAdn = loadable(async () =>
    import(/* webpackChunkName: "chunk-advertising-adn" */ './advertising-adn'),
  );

  return advertisignAdnProps ? <AdvertisingAdn {...advertisignAdnProps} /> : null;
};

export default ChunkAdvertisingAdn;
