import React from 'react';

import { loadable } from 'nordic/lazy';

import useRelatedSearches from './hooks/use-related-searches';

const ChunkRelatedSearches = () => {
  const relatedSearchesProps = useRelatedSearches();

  const RelatedSearches = loadable(async () =>
    import(/* webpackChunkName: "chunk-related-searches" */ './related-searches.mobile'),
  );

  const hasRelatedSearches = relatedSearchesProps?.related_searches?.length > 0;

  return hasRelatedSearches ? <RelatedSearches {...relatedSearchesProps} /> : null;
};

export default ChunkRelatedSearches;
