import React from 'react';

import { loadable } from 'nordic/lazy';

import useCoachMark from './use-coachmark';

const ChunkCoachMark = () => {
  const coachMarkProps = useCoachMark();
  const CoachMark = loadable(async () => import('./coachmark.mobile'));

  return coachMarkProps
    ? coachMarkProps.map((coachMarkDataItem) => (
        <CoachMark coachMarkData={coachMarkDataItem} key={`CM${coachMarkDataItem?.type}`} />
      ))
    : null;
};

export default ChunkCoachMark;
