import React from 'react';
import { node } from 'prop-types';

import useEshopsEnvironment from '../../hooks/use-eshops-environment';
import withComponentHandler from '../../hocs/with-component-handler';

/**
 * @typedef {Object} WrapperEshopMobileProps
 * @property {React.ReactNode} children - Los componentes hijos que serán renderizados.
 */

/**
 * Componente que envuelve y procesa los componentes hijos para entornos de eShops.
 *
 * @param {WrapperEshopMobileProps} props - Las propiedades del componente.
 * @param {React.ReactNode} props.children - Los componentes hijos que serán renderizados.
 * @returns {React.ReactNode} Los componentes renderizados, envueltos en un contenedor si es un entorno de eShops.
 */
const WrapperEshopMobile = ({ children }) => {
  const { isEshopsEnvironment: isEshop } = useEshopsEnvironment();

  if (isEshop) {
    return <div className="eshops">{children}</div>;
  }

  return children;
};

WrapperEshopMobile.propTypes = {
  children: node.isRequired,
};

export const EshopMobile = withComponentHandler(WrapperEshopMobile, { name: 'WrapperEshopMobile' });
