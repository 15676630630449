/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable import/order */
import React from 'react';

import { string, shape, arrayOf } from 'prop-types';
import { loadable } from 'nordic/lazy';
import get from 'lodash/get';
import useResults from './use-results';
import { useStaticProps } from '../context/static-props';
import { LAYOUTS, VISUAL_IDS } from '../../constants';

// Layout Items
import MobileLayoutItem from './renderer/layout-item/mobile';

import MobileLayoutItemPI from './renderer/layout-item/mobile.pi';
import MapLayoutItem from './renderer/layout-item/map';

// Layout columns
const OneColumnLayout = loadable(() => import('./columns/one-column'));
const TwoColumnMosaicLayout = loadable(() => import('./columns/two-column-mosaic'));
const OneColumnLayoutStack = loadable(() => import('./columns/one-column-stack'));
const MapLayout = loadable(() => import('./columns/map'));
const GridColumnLayout = loadable(() => import('./columns/grid-column'));

const getLayoutByName = (name, deviceType, visualId) => {
  const isMobile = deviceType === 'mobile';
  const isPI = visualId === VISUAL_IDS.PI;

  switch (name) {
    case LAYOUTS.TYPE_MOSAIC:
      return TwoColumnMosaicLayout;
    case LAYOUTS.TYPE_GRID:
      return GridColumnLayout;
    case LAYOUTS.TYPE_STACK:
      return isMobile && !isPI ? OneColumnLayoutStack : OneColumnLayout;
    case LAYOUTS.TYPE_MAP:
      return MapLayout;
    case LAYOUTS.TYPE_GALLERY:

    // falls through
    default:
      return OneColumnLayout;
  }
};

const LayoutItems = {
  mobile: {
    [VISUAL_IDS.PI]: MobileLayoutItemPI,
    [VISUAL_IDS.DEFAULT]: MobileLayoutItem,
  },
};

const getLayoutItem = (layoutName, deviceType, visualId) => {
  if (layoutName === LAYOUTS.TYPE_MAP) {
    return MapLayoutItem;
  }

  return get(LayoutItems, `${deviceType}.${visualId || VISUAL_IDS.DEFAULT}`);
};

const LayoutManager = ({ deviceType, name, layout, results, polycard_context, ...rest }) => {
  const { visualId } = useStaticProps();
  const LayoutComponent = layout || getLayoutByName(name, deviceType, visualId);
  const LayoutItemComponent = getLayoutItem(name, deviceType, visualId);

  return (
    <LayoutComponent
      layout={name}
      itemComponent={LayoutItemComponent}
      results={results}
      polycard_context={polycard_context}
      deviceType={deviceType}
      {...rest}
    />
  );
};

LayoutManager.propTypes = {
  deviceType: string,
  layout: string,
  name: string.isRequired,
  polycard_context: shape({}),
  results: arrayOf(shape),
};

LayoutManager.defaultProps = {
  layout: '',
  deviceType: '',
  polycard_context: null,
  results: [],
};

const LayoutManagerContainer = () => {
  const { currentLayout, results, polycard_context, layout_options } = useResults();

  const current = currentLayout === undefined ? layout_options.current : currentLayout.id;
  const { deviceType } = useStaticProps();

  return <LayoutManager name={current} results={results} polycard_context={polycard_context} deviceType={deviceType} />;
};

export default LayoutManagerContainer;
export { LayoutManager };
