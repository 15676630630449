import React from 'react';

import { loadable } from 'nordic/lazy';

import useAlert from './use-alert';

const ChunkSearchSave = () => {
  const alertProps = useAlert();
  const SearchSave = loadable(async () => import(/* webpackChunkName: "chunk-alert" */ './search-save.mobile'));

  return alertProps ? <SearchSave {...alertProps} /> : null;
};

export default ChunkSearchSave;
