import React from 'react';

import { loadable } from 'nordic/lazy';

import useTopSwitchFilters from './use-top-switch-filters';

const ChunkTopSwitchFilters = () => {
  const topSwitchFiltersProps = useTopSwitchFilters();
  const TopSwitchFilter = loadable(async () =>
    import(/* webpackChunkName: "chunk-top-switch-filters" */ './top-switch-filters'),
  );

  return topSwitchFiltersProps
    ? topSwitchFiltersProps.map((top_switch_filter) => (
        <TopSwitchFilter key={`top-filter--${top_switch_filter.id}`} {...top_switch_filter} />
      ))
    : null;
};

export default ChunkTopSwitchFilters;
