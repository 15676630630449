import React from 'react';

import { loadable } from 'nordic/lazy';

import useBillboard from './use-billboard';

const ChunkBillboard = () => {
  const { billboard, lowEnd, billboardState, bookmark } = useBillboard();
  const Billboard = loadable(async () => import(/* webpackChunkName: "chunk-billboard" */ './billboard.mobile'));

  if (!billboard || lowEnd) {
    return null;
  }

  return (
    <Billboard
      {...billboard}
      lowEnd={lowEnd}
      state={billboardState}
      showUserBookmarks={bookmark?.show_user_bookmarks}
    />
  );
};

export default ChunkBillboard;
