/* eslint-disable  jsx-a11y/prefer-tag-over-role */
import React, { useState } from 'react';
import { object, string, shape, arrayOf } from 'prop-types';

import Image from 'nordic/image';
import get from 'lodash/get';

const namespace = 'ui-search-variations-picker-mobile';

const VariationsPicker = ({ variations, renderOptions, layout }) => {
  const { options } = variations;
  const selectedItem = options.find((item) => item.is_selected);
  const [pickedVariation, setPickedVariation] = useState(selectedItem ? selectedItem.id : options[0].id);
  const thumbnailsToShow = get(variations, `thumbnails_to_show.${layout}`);
  const getClassName = (variation) =>
    `${namespace}__${pickedVariation === variation ? 'variation-selected' : 'variation'}`;
  const getPermalink = (option) => option?.item?.permalink;
  const variationsQuantity = thumbnailsToShow ? 2 : 3;
  const variationsToShow = options.filter((variation, index) => index < variationsQuantity);

  return (
    <div className={namespace}>
      {variationsToShow.map((variation) => (
        <object>
          <a
            aria-label={variation.name}
            href={getPermalink(variation)}
            role="button"
            onClick={() => {
              setPickedVariation(variation.id);
            }}
          >
            <Image
              src={get(variation, 'thumbnail.retina')}
              className={getClassName(variation.id)}
              alt={variation.name}
              lowEnd={renderOptions.lowEnd}
            />
          </a>
        </object>
      ))}
      {thumbnailsToShow?.show_more_text && (
        <object>
          <a
            className={getClassName('label-more-options')}
            aria-label={thumbnailsToShow.show_more_text}
            href={getPermalink(selectedItem)}
            role="button"
            onClick={() => setPickedVariation('label-more-options')}
          >
            <span>{thumbnailsToShow.show_more_text}</span>
          </a>
        </object>
      )}
    </div>
  );
};

VariationsPicker.propTypes = {
  layout: string,
  renderOptions: object,
  variations: shape({
    thumbnails_to_show: shape({
      stack: shape({}),
      grid: shape({}),
      default: shape({}),
    }),
    options: arrayOf(object).isRequired,
  }).isRequired,
};

VariationsPicker.defaultProps = {
  renderOptions: { lowEnd: false },
  variations: {},
  layout: 'grid',
};

export default VariationsPicker;
