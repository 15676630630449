import React from 'react';

import { loadable } from 'nordic/lazy';

import { useSearch } from '../../hooks/context';
import ComponentList from '../../utils/component-list/index';

const CreditLineFilterMobile = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-credit-line-filter-mobile" */
    '../../components-v2/credit-line-filter/index'
  ),
);

const availableComponents = {
  CREDIT_LINE_FILTER_INTERVENTION: CreditLineFilterMobile,
};

const StickyComponents = () => {
  const { sticky_components } = useSearch();

  return <ComponentList availableComponents={availableComponents} components={sticky_components} />;
};

export default StickyComponents;
