import React from 'react';

import { loadable } from 'nordic/lazy';

import useTopKeywords from './use-top-keywords';

const ChunkTopKeywordsContainer = () => {
  const PropsTopKeywords = useTopKeywords();
  const TopKeywords = loadable(async () =>
    import(/* webpackChunkName: "chunk-top-keywords" */ './top-keywords.mobile'),
  );

  const hasTopKeywords = PropsTopKeywords && PropsTopKeywords.keywords.length > 0;

  return hasTopKeywords ? <TopKeywords {...PropsTopKeywords} /> : null;
};

export default ChunkTopKeywordsContainer;
