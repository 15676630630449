import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-card-attributes';

const Attributes = ({ className, attributes }) => {
  if (attributes.length <= 0) {
    return null;
  }

  return (
    <ul className={classnames(namespace, className)}>
      {attributes.map((attribute) => (
        <li key={attribute.id}>{attribute.label}</li>
      ))}
    </ul>
  );
};

Attributes.defaultProps = {
  attributes: [],
  className: null,
};

Attributes.propTypes = {
  attributes: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
    }),
  ),
  className: string,
};

export default Attributes;
