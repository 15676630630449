import React from 'react';
import { string, bool } from 'prop-types';

import { loadable } from 'nordic/lazy';

import useAppliedFilters from './use-applied-filters';

const ChunkAppliedFiltersContainer = ({ className, isInternal }) => {
  const visibleFiltersProps = useAppliedFilters({ isInternal });
  const AppliedFilters = loadable(async () =>
    import(/* webpackChunkName: "chunk-applied-filters" */ './applied-filters.mobile'),
  );

  return visibleFiltersProps && visibleFiltersProps.length > 0 ? (
    <AppliedFilters className={className} appliedFilters={visibleFiltersProps} />
  ) : null;
};

ChunkAppliedFiltersContainer.defaultProps = {
  className: '',
  isInternal: false,
};

ChunkAppliedFiltersContainer.propTypes = {
  className: string,
  isInternal: bool,
};

export { ChunkAppliedFiltersContainer };
