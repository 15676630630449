import React from 'react';

import classnames from 'classnames';
import has from 'lodash/has';

import { renderHighlightGroup, truncateString } from '../desktop-card-content/common';
import { VERTICAL_TYPE_RES } from '../constants';
import { LAYOUTS } from '../../../../constants';
import ItemTitle from '../../../card/title';
import ItemAttributes from '../../../card/attributes/attributes.mobile';
import ItemOfficialStore from '../../../card/official-store/official-store-label';
import HightlightLabel from '../../../card/highlight/highlight-label';
import ItemPrice from '../../../card/price';
import IconOnlineVisitRequest from '../../../../components-v2/icons/sources/online-visit-request';

const namespace = 'ui-search-result';

function renderPrice(item) {
  if (!has(item, 'price.amount')) {
    return <div className="ui-search-item__price_label">{item.price.label.text}</div>;
  }

  return <ItemPrice {...item.price} discountSource={item.discount_source} from={item.from} />;
}

// eslint-disable-next-line complexity
const CardContentMobile = (cardContentProps) => {
  const {
    item,
    layout,
    vertical,
    subtitles,
    possession,
    available_units,
    options,
    highlighted,
    className,
    permalink,
    target,
  } = cardContentProps;

  if (vertical === VERTICAL_TYPE_RES && layout === LAYOUTS.TYPE_GALLERY) {
    return (
      <>
        <div className={`${namespace}__content`}>
          {subtitles?.operation && <div className="ui-search-item__operation">{subtitles.operation}</div>}
          {subtitles?.item_title && (
            <div className="ui-search-item__title-development">
              <ItemTitle title={subtitles.item_title} className={className} permalink={permalink} target={target} />
            </div>
          )}
          {subtitles?.development && (
            <div className="ui-search-item__title-development">
              <ItemTitle title={subtitles.development} className={className} permalink={permalink} target={target} />
            </div>
          )}
          <div className="ui-search-item__price">
            <div className="ui-search-item__price-label">{renderPrice(item)}</div>
            <div className="ui-search-item__highlight-group-label">{renderHighlightGroup(item)}</div>
            {item.descriptions && (
              <div className="ui-search-item__attributes-grid-container">
                <ItemAttributes attributes={item.descriptions} className="ui-search-item__attributes-grid" />
              </div>
            )}
            {item.location && (
              <div className="ui-search-item__description-container">
                <div className="ui-search-item__location">{truncateString(item.location, 50)}</div>
              </div>
            )}
            {possession && (
              <div className="ui-search-item__possession-wrapper">
                <span className="ui-search-item__possession">{possession}</span>
              </div>
            )}
            {available_units && (
              <div className="ui-search-item__available-units-container">
                <span className="ui-search-item__available-units">{available_units}</span>
              </div>
            )}
            {item.online_visit_request && (
              <div className="ui-search-item__online-visit-container-grid-pi">
                <IconOnlineVisitRequest />
                <span className="ui-search-item__online-visit-label">{item.online_visit_request}</span>
              </div>
            )}
          </div>
          {item.official_store && (
            <div className="ui-search-item__official-store-grid-container">
              <ItemOfficialStore
                label={options.verboseLabels ? item.official_store.verbose_text : item.official_store.text}
                className="official-store--pi"
                layout={layout}
              />
            </div>
          )}
          <div
            className={classnames('ui-search-item__highlighted-bottom', {
              [`flex-end`]: !item.media,
            })}
          >
            {item.media && <HightlightLabel {...item.media} />}
            {highlighted && (
              <div className="ui-search-item__highlighted-wrapper">
                <span className="ui-search-item__highlighted">{highlighted}</span>
              </div>
            )}
          </div>
        </div>
        {item.is_ad && <span className="ui-search-item__ad_label">{item.ad_label}</span>}
      </>
    );
  }

  return (
    <>
      <div className={`${namespace}__content`}>
        {renderHighlightGroup(item)}
        {item.media && <HightlightLabel {...item.media} />}
        <div className="ui-search-item__price">
          {renderPrice(item)}
          {item.descriptions && (
            <ItemAttributes attributes={item.descriptions} className="ui-search-item__attributes--res" />
          )}
        </div>
        <div className="ui-search-item__description">
          {subtitles?.operation && <div className="ui-search-item__operation">{subtitles.operation}</div>}
          <div className="ui-search-item__location">{item.location}</div>
          {subtitles?.item_title && <ItemTitle title={subtitles.item_title} />}
          {subtitles?.development && <ItemTitle title={subtitles.development} />}
          {available_units && (
            <div className="ui-search-item__available-units-wrapper">
              <span className="ui-search-item__available-units">{available_units}</span>
            </div>
          )}
          {item.official_store && (
            <ItemOfficialStore
              label={options.verboseLabels ? item.official_store.verbose_text : item.official_store.text}
              className="official-store--pi"
            />
          )}
        </div>
        {possession && (
          <div className="ui-search-item__possession-wrapper">
            <span className="ui-search-item__possession">{possession}</span>
          </div>
        )}
        {highlighted && (
          <div className="ui-search-item__highlighted-wrapper">
            <span className="ui-search-item__highlighted">{highlighted}</span>
          </div>
        )}
      </div>
      {item.is_ad && <span className="ui-search-item__ad_label">{item.ad_label}</span>}
    </>
  );
};

export default CardContentMobile;
