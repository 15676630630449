import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-variations';

const Variations = ({ className, label }) => <p className={classnames(namespace, className)}>{label}</p>;

Variations.propTypes = {
  className: string,
  label: string.isRequired,
};

Variations.defaultProps = {
  className: null,
};

export default Variations;
