/* eslint-disable react/jsx-no-useless-fragment */
import React, { useLayoutEffect, useRef } from 'react';
import { number, shape, string, bool, func } from 'prop-types';

import Image from 'nordic/image';
import classnames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import Card, { CardContent } from '@andes/card';

import StyledLabelWrapper from '../../../styled-label/styled-label';
import Carousel from '../carousel-snapped/carousel.mobile';
import Wrapper from '../cpg/wrapper';
import ItemTitle from '../../../card/title';
import {
  renderTitle,
  renderOfficialStore,
  truncateString,
  renderMediaTagGroup,
  renderVerticalHighlightGroup,
  renderHighlightGroup,
} from '../desktop-card-content/common';
import { renderFreeShippingThMessage } from '../desktop-card-content/core';
import ItemBrandDiscoverability from '../../../card/brand';
import ItemSpotlightGroup from '../../../card/spotlight';
import ItemPrice from '../../../card/price';
import ItemShippingGroup from '../../../card/shipping';
import ItemAttributes from '../../../card/attributes/attributes.mobile';
import ItemBookmark from '../../../../components-v2/bookmark/bookmark';
import ItemVariations from '../../../card/variations/variations.mobile';
import ItemVariationsPicker from '../../../card/variations/variations-picker.mobile';
import ItemCondition from '../../../card/condition-text/condition.mobile';
import ItemOfficialStore from '../../../card/official-store/official-store-label';
import ItemInstallments from '../../../card/installments/installments';
import ItemMeliCoin from '../../../card/melicoin';
import Link from '../../../../components-v2/link';
import ItemRebates from '../../../card/rebates';
import ItemHighlight from '../../../card/highlight/highlight-label';
import Reviews from '../../../reviews/reviews';
import Group from '../../../card/group/group';
import PdsBestPrice from '../../../card/pds/pds-best-price';
import PdsOptions from '../../../card/pds/pds-options';
import PriceDetails from '../../../card/price-details/price-details';
import PillsBuilder from '../../../pills-builder';
import Pill from '../../../pill/Pill';
import PromiseMessage from '../../../card/promise-message';
import { useSearch } from '../../../../hooks/context';
import { getEstimatedCardContentWidth } from '../utils';
import WinnerAlternatives from '../../../winner-alternatives/winner-alternatives';
import IconOnlineVisitRequest from '../../../../components-v2/icons/sources/online-visit-request';
import {
  VERTICAL_TYPE_CPG,
  VERTICAL_TYPE_SRV,
  VERTICAL_TYPE_CORE,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
  CARD_DATA_GROUPS,
  LAZY_ON,
  LAZY_OFF,
  configPreload,
  namespace,
  GALLERY,
  GRID,
} from '../constants';

function renderPrice(item) {
  if (!has(item, 'price.amount')) {
    return has(item, 'price.label') ? <div className="ui-search-item__price_label">{item.price.label.text}</div> : null;
  }

  return <ItemPrice {...item.price} discountSource={item.discount_source} from={item.from} />;
}

const renderSpotlight = (item, position = null) => {
  const spotlight = get(item, 'spotlight', []);
  const filteredSpotlight = spotlight.filter((s) => (position ? s.position === position : !s.position));

  return filteredSpotlight.length > 0 ? <ItemSpotlightGroup spotlight={filteredSpotlight} /> : null;
};

const renderShippingGroup = (item, layout) => {
  const itemTags = get(item, 'tags');
  const contentWidth = getEstimatedCardContentWidth('mobile', layout);

  return <ItemShippingGroup shipping={item.shipping} tags={itemTags} contentWidth={contentWidth} />;
};

const renderPriceGroup = (item, layout) => (
  <Group name={CARD_DATA_GROUPS.PRICE}>
    {item.price && renderPrice(item)}
    {item.price_details && <PriceDetails priceDetails={item.price_details} />}
    {item.installments && <ItemInstallments installments={item.installments} price={item.price} layout={layout} />}
  </Group>
);

const renderWinnerAlternativesOrPrice = (item, layout) => {
  if (has(item, 'product.alternatives')) {
    return (
      <WinnerAlternatives itemId={item.id} alternativeProp="alternatives" alternatives={item.product.alternatives} />
    );
  }

  return renderPriceGroup(item, layout);
};
const renderBottomAlternatives = (item) =>
  has(item, 'product.bottom_alternatives') && (
    <WinnerAlternatives
      itemId={item.id}
      alternativeProp="bottom_alternatives"
      alternatives={item.product.bottom_alternatives}
    />
  );

const Pills = (pills, l) => <>{pills && pills.length > 0 && <PillsBuilder pills={pills} l={l} />}</>;
const renderPills = ({ pills = [] }, l) => Pills(pills, l);
const renderBottomPills = ({ bottom_pills = [] }, l) => Pills(bottom_pills, l);

function renderCoreContent(item, options, layout) {
  const itemHighlightInfo = get(item, 'item_highlight', get(item, 'highlight'));
  const isUsed = item.condition === 'used';
  const isRefurbished = item.condition === 'refurbished';
  const isInternationalText = item.international_text;

  const isCpg = !!item.cpg;
  const className = classnames(`${namespace}__content`, {
    [`${namespace}__content--core`]: !isCpg,
    [`${namespace}__content--pill-p`]: item.is_ad,
  });

  const contentWidth = getEstimatedCardContentWidth('mobile', layout);

  const renderPillPromotions = ({ promotions }, currentLayout) => (
    <>
      {promotions &&
        promotions.length > 0 &&
        promotions.map((element) => <Pill element={element} layout={currentLayout} />)}
    </>
  );

  const renderPromiseLabel = (manufacturing_time) =>
    manufacturing_time ? <PromiseMessage manufacturing_time={manufacturing_time} /> : null;

  return (
    <div className={className}>
      {item.variations_picker && (
        <Group name={CARD_DATA_GROUPS.VARIATIONS_PICKER}>
          <ItemVariationsPicker variations={item.variations_picker} renderOptions={options} layout={layout} />
        </Group>
      )}
      {itemHighlightInfo && (
        <Group name={CARD_DATA_GROUPS.HIGHLIGHT}>
          <ItemHighlight {...itemHighlightInfo} />
        </Group>
      )}
      <Group name={CARD_DATA_GROUPS.TITLE}>
        {item.brand_discoverability && (
          <Group name={CARD_DATA_GROUPS.BRAND_DISCOVERABILITY}>
            <ItemBrandDiscoverability brand={item.brand_discoverability.text} />
          </Group>
        )}
        {renderTitle(item.title, item.sub_title, item.permalink, item.target, item.is_ad, item.title_compats)}
        {item.reviews && (
          <Reviews
            rating={item.reviews.rating_average}
            stars={item.reviews.rating_stars}
            formattedTotal={item.reviews.formatted_total_label || item.reviews.formatted_total}
            formattedAverage={item.reviews.formatted_rating_average || item.reviews.rating_average_formatted}
            accesibilityText={item?.reviews?.accessibility_text || item.reviews.alt_text}
          />
        )}
      </Group>
      {renderSpotlight(item)}
      {renderWinnerAlternativesOrPrice(item, layout)}
      {item.melicoin && <ItemMeliCoin {...item.melicoin} />}
      {renderPillPromotions(item, layout)}
      {renderPills(item, layout)}
      {isCpg && renderFreeShippingThMessage(item, layout)}
      <Group name={CARD_DATA_GROUPS.REBATES}>
        {item.rebates && item.rebates.length > 0 && <ItemRebates rebates={item.rebates} contentWidth={contentWidth} />}
      </Group>
      {renderShippingGroup(item, layout)}
      {renderSpotlight(item, 'bottom')}
      {item.manufacturing_time && renderPromiseLabel(item.manufacturing_time)}
      {renderVerticalHighlightGroup(item)}
      <Group>
        {item.variations && <ItemVariations label={item.variations.label} />}
        {item.official_store && (
          <ItemOfficialStore
            label={options.verboseLabels ? item.official_store.verbose_text : item.official_store.text}
            has_official_store_icon={item.official_store.has_official_store_icon}
          />
        )}
        {(isUsed || isRefurbished || isInternationalText) && <ItemCondition item={item} />}
      </Group>
      {item.pds_info && (
        <Group>
          <PdsBestPrice {...item.pds_info.best_price} />
          <PdsOptions {...item.pds_info.label} />
        </Group>
      )}
      {renderBottomPills(item, layout)}
      {renderBottomAlternatives(item)}
    </div>
  );
}

function renderMotContent(item) {
  const itemDescription = item.descriptions ?? item.verticalInfo?.descriptions;
  const itemHighlightInfo = item.verticalInfo?.item_highlight;

  return (
    <div className={`${namespace}__content ${namespace}__content--mot`}>
      {itemHighlightInfo && (
        <Group name={CARD_DATA_GROUPS.HIGHLIGHT}>
          <ItemHighlight {...itemHighlightInfo} />
        </Group>
      )}
      <Group className="ui-search-item__group--title">
        {renderTitle(item.sub_title, item.permalink, item.target, item.is_ad)}
      </Group>
      <Group className="ui-search-item__group--price">
        {renderPrice(item)}
        {itemDescription && (
          <ItemAttributes
            attributes={itemDescription}
            className="ui-search-item__attributes ui-search-item__attributes--mot"
          />
        )}
        {item.official_store && <ItemOfficialStore label={item.official_store.verbose_text} />}
      </Group>
    </div>
  );
}

function renderResContent(item, options, layout) {
  const itemSubtitle = item?.subtitles?.operation ? item.subtitles.operation : item.title;

  return (
    <Group className={`${namespace}__content`}>
      <Group className="ui-search-item__group--title">
        {itemSubtitle && <span className="ui-search-item__subtitle">{itemSubtitle}</span>}
        <div className="ui-search-item__title-grid">
          {renderTitle(item.title, layout, item.permalink, item.target, item.is_ad, item.title_compats)}
        </div>
      </Group>
      <Group>
        <div className="ui-search-item__price-grid">{renderPrice(item)}</div>
        <div className="ui-search-item__highlight-grid-container">{renderHighlightGroup(item)}</div>
        {item.descriptions && (
          <div className="ui-search-item__attributes-grid-container">
            <ItemAttributes attributes={item.descriptions} className="ui-search-item__attributes-grid" />
          </div>
        )}
        {item.location && (
          <div className="ui-search-item__location-grid-container">
            <div className="ui-search-item__location-grid-label">{truncateString(item.location, 50)}</div>
          </div>
        )}
        {item.possession && (
          <div className="ui-search-item__possession-grid-container">
            <div className="ui-search-item__possession-label">{item.possession.replace(':', '')}</div>
          </div>
        )}
        {item.available_units && (
          <div className="ui-search-item__available-units-grid-container">
            <div className="ui-search-item__available-units-grid-label">{item.available_units}</div>
          </div>
        )}
        {item.online_visit_request && (
          <div className="ui-search-item__online-visit-container-grid">
            <IconOnlineVisitRequest />
            <span className="ui-search-item__online-visit-label">{item.online_visit_request}</span>
          </div>
        )}
      </Group>
      {item.official_store && (
        <div className="ui-search-item__official-store-grid-container">
          {renderOfficialStore(item.vertical, item.official_store, null, null, layout)}
        </div>
      )}
      <div
        className={classnames('ui-search-item__highlighted-wrapper', {
          'flex-end': !item.media,
        })}
      >
        {renderMediaTagGroup(item)}
        {item.highlighted && <span className="ui-search-item__highlighted">{item.highlighted}</span>}
      </div>
    </Group>
  );
}

function renderSrvContent(item) {
  return (
    <div className={`${namespace}__content ui-search-result__content--srv`}>
      <Group className="ui-search-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      <Group>{renderPrice(item)}</Group>
      <Group>
        {item.descriptions && (
          <ItemAttributes
            attributes={item.descriptions}
            className="ui-search-item__attributes ui-search-item__attributes--srv"
          />
        )}
      </Group>
    </div>
  );
}

function renderCatContent({ item, options, layout }) {
  const itemTags = get(item, 'tags');
  const contentWidth = getEstimatedCardContentWidth('mobile', layout);

  return (
    <div className={`${namespace}__content ui-search-result__content--srv`}>
      <Group name={CARD_DATA_GROUPS.TITLE}>
        {item.brand_discoverability && (
          <Group name={CARD_DATA_GROUPS.BRAND_DISCOVERABILITY}>
            <ItemBrandDiscoverability brand={item.brand_discoverability.text} />
          </Group>
        )}
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      <Group name={CARD_DATA_GROUPS.PRICE}>
        {item.price && renderPrice(item)}
        {item.price_details && <PriceDetails priceDetails={item.price_details} />}
        {item.installments && <ItemInstallments installments={item.installments} price={item.price} layout={layout} />}
      </Group>
      <ItemShippingGroup shipping={item.shipping} tags={itemTags} contentWidth={contentWidth} />
      {item.official_store && (
        <ItemOfficialStore
          label={options.verboseLabels ? item.official_store.verbose_text : item.official_store.text}
        />
      )}
    </div>
  );
}

function verticalContentRenderer(item, options, layout, frontend_layout_type) {
  const isCategoryPage = frontend_layout_type === 'CATEGORY';
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_SRV:
      return renderSrvContent(item, options, layout);
    case VERTICAL_TYPE_MOT:
      return renderMotContent(item, options, layout);
    case VERTICAL_TYPE_RES:
      return renderResContent(item, options, layout);
    case VERTICAL_TYPE_CPG:
    case VERTICAL_TYPE_CORE:

    // falls through
    default:
      return isCategoryPage ? renderCatContent({ item, options, layout }) : renderCoreContent(item, options, layout);
  }
}

// eslint-disable-next-line react/prop-types
const AdLabel = ({ label, ads_metadata }) => (
  <StyledLabelWrapper color={ads_metadata?.ui?.font_color} text={label} className="ui-search-item__pub-label" />
);

const renderShopsPills = (item) => <div className={`${namespace}__shops-pills--absolute`}>{renderPills(item)}</div>;

// TODO: render creado para la VI
const MobileLayoutItem = ({ item, layout, index, options, onMount, imageGridHeight }) => {
  const { title, vertical, permalink, is_ad, image_ratio, pictures } = item;
  const { bookmark, frontend_layout_type, shops, ads_metadata } = useSearch();
  const isShops = Boolean(shops);
  const isGrid = layout === GRID;
  const itemVertical = vertical ? vertical.toLowerCase() : null;
  const filterCpgVertical = isGrid && itemVertical === 'cpg' ? 'core' : itemVertical;
  const preload = index < configPreload[`${layout}`];
  const isCpg = Boolean(item.cpg);
  const wrapperClassname = classnames(`${namespace}__item_wrapper`, {
    [`${namespace}--${filterCpgVertical}__item_wrapper`]: itemVertical,
  });
  const imageContainerClassname = classnames(`${namespace}__item-image-container`, {
    [`${namespace}--${filterCpgVertical}__item-image-container`]: itemVertical,
  });
  const imageLinkClassname = classnames(`${namespace}__image__link`, {
    [`${namespace}__image__link--${filterCpgVertical}`]: itemVertical,
  });
  const figureClassname = classnames(`${namespace}__image_container`, {
    [`${namespace}__image_container--${filterCpgVertical}`]: itemVertical,
  });

  const cardRef = useRef(null);

  useLayoutEffect(() => {
    if (cardRef.current && onMount) {
      onMount({
        ...item,
        index,
        height: cardRef.current.getBoundingClientRect().height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adLabel = item.is_ad && <AdLabel label={item.ad_label} ads_metadata={ads_metadata} />;

  return (
    <Card animated={false}>
      <CardContent
        className={classnames(namespace, {
          [`${namespace}--${filterCpgVertical}`]: itemVertical,
        })}
      >
        {isShops && renderShopsPills(item)}
        <div className={wrapperClassname} ref={cardRef}>
          <div className={imageContainerClassname}>
            {(vertical === VERTICAL_TYPE_RES || vertical === VERTICAL_TYPE_MOT) && layout === GALLERY ? (
              <Carousel
                item={item}
                index={index}
                layout={layout}
                onMount={onMount}
                cardRef={cardRef}
                vertical={vertical}
                imageRatio={image_ratio}
                itemVertical={itemVertical}
                title={title}
                permalink={permalink}
                isAd={is_ad}
                options={options}
                pictures={pictures}
              />
            ) : (
              <Link
                className={imageLinkClassname}
                href={permalink}
                title={title}
                tabIndex="-1"
                isInternal={false}
                isAd={is_ad}
              >
                <figure
                  className={classnames(figureClassname, {
                    [`${namespace}__crop-image`]: get(pictures, `${layout}.tags.should_crop`),
                  })}
                  style={imageGridHeight ? { height: imageGridHeight } : {}}
                >
                  <div className={`${namespace}__overlay-image`} />
                  <Image
                    alt={title}
                    className={`${namespace}__image`}
                    src={get(pictures, `${layout}.retina`)}
                    width={get(pictures, `${layout}.tags.width`)}
                    height={get(pictures, `${layout}.tags.heigth`)}
                    preload={preload}
                    lazyload={preload ? LAZY_OFF : LAZY_ON}
                    lowEnd={options.lowEnd === true}
                  />
                </figure>
              </Link>
            )}
          </div>
          <Wrapper
            adLabel={adLabel}
            className={classnames(`${namespace}-wrapper`, {
              [`${namespace}__cpg-wrapper`]: isCpg,
              [`${namespace}__cpg-wrapper--ads`]: isCpg && Boolean(adLabel),
              [`${namespace}__shops-content-wrapper`]: isShops,
            })}
            isCpg={isCpg}
            item={item}
            isGrid={isGrid}
            isMobile
          >
            {verticalContentRenderer(item, options, layout, frontend_layout_type)}
            {!isCpg && adLabel}
          </Wrapper>
        </div>
        {bookmark?.show_user_bookmarks && !item.pds_info && (
          <ItemBookmark
            className={classnames({
              'ui-search-bookmark__cpg': item.vertical === VERTICAL_TYPE_CPG && !isGrid,
            })}
            item={item}
          />
        )}
      </CardContent>
    </Card>
  );
};

MobileLayoutItem.propTypes = {
  imageGridHeight: number,
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onMount: func,
};

MobileLayoutItem.defaultProps = {
  imageGridHeight: null,
  onMount: null,
  options: {},
};

export default MobileLayoutItem;
